import React from 'react';
import './JobPost.css'; // Ensure you have this CSS file for styles
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const sendEmail = () => {
  window.location.href = "mailto:support@avvikelser.com";
};



const JobPost = () => {
  return (


    <div className="job-background">
    <header className="app-header">
        <div className="header-content">

          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/assets/retailx.png`} alt="Retail X Logo" className="header-logo" />
          </Link>
                <button className="contact-button" onClick={sendEmail}>Contact Us</button>
        </div>
    </header>
      <div className="job-card">
        <h3>Join Our Innovative Team!</h3>
        <br />
       
        <p>RetailX is a dynamic and fast-growing company specializing in helping influencers create unique products for their communities. We focus on product design, development, supply chains, and all aspects needed to transform an idea into a final product. As we continue to expand, we're seeking a <strong>Co-founder</strong> to join our mission and help shape the future of retail technology. This role is ideal for someone with entrepreneurial spirit, strategic vision, and the ability to execute in a fast-paced environment.</p>
<br />
        
        <h4>Key Responsibilities:</h4>

<br />

        <ul>
          <li>Strategic Leadership: Help define and execute the company's vision and growth strategy.</li>
          <li>Product Development: Drive product innovation and market fit for our retail technology solutions.</li>
          <li>Business Development: Build and maintain key partnerships and client relationships.</li>
          <li>Team Building: Help build and lead high-performing teams across different functions.</li>
          <li>Operations: Establish and optimize core business processes and systems.</li>
          <li>Market Strategy: Identify market opportunities and develop go-to-market strategies.</li>
        </ul>
<br />

        <h4>Qualifications:</h4>
<br />

        <ul>
          <li>Proven entrepreneurial experience or leadership roles in startups/tech companies.</li>
          <li>Strong background in retail, e-commerce, or technology sectors.</li>
          <li>Track record of building and scaling products or businesses.</li>
          <li>Excellent strategic thinking and execution capabilities.</li>
          <li>Strong network and ability to build partnerships.</li>
          <li>Technical understanding of software products and digital platforms.</li>
          <li>Resilient, self-motivated, and thrives in uncertainty.</li>
        </ul>

<br />
        <h4>Benefits:</h4>
<br />

        <p>Initially commission-based salary with equity package option. Flexible working hours. Social gatherings, skill development opportunities, and office perks like coffee and tea. A unique opportunity to work in a thriving startup environment, directly contributing to the company's growth and success.</p>
<br />
      
        <p><strong>Apply by sending an email.</strong></p>
      </div>
    </div>
  );
}

export default JobPost;
