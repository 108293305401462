import React from 'react';
import './App.css'; // Ensure this reflects the actual path to your CSS file

import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const sendEmail = () => {
  window.location.href = "mailto:support@avvikelser.com";
};

const LandingPage = () => {
  return (
    <div className="app">
      <header className="app-header">
        <div className="header-content">
          <img src={`${process.env.PUBLIC_URL}/assets/retailx.png`} alt="Retail X Logo" className="header-logo" /> 

          <div className="header-buttons">
            <Link to="/jobs">
              <button className="contact-button">Jobs</button>
            </Link>
            <button className="contact-button" onClick={sendEmail}>Contact Us</button>
          </div>
        </div>
      </header>

      <main className="main-content">
        <h2 className="investments-title">Our products</h2>
        <div className="cards-container">

          <a href="https://avvikelser.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card">
              <img src={`${process.env.PUBLIC_URL}/assets/avvikelser.png`} alt="Avvikelser Logo" className="header-logo" />
              <div className="card-content">
                <h2>Avvikelser - Track of deliveries and credit invoices</h2>
              </div>
            </div>
          </a>

          <a href="https://kollis.se" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card">
              <img src={`${process.env.PUBLIC_URL}/assets/kollis.png`} alt="Kollis Logo" className="header-logo" />
              <div className="card-content">
                <h2>Kollis - Easy location validation for new establishments.</h2>
              </div>
            </div>
          </a>

          <a href="https://inventering.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card">
              <img src={`${process.env.PUBLIC_URL}/assets/inventering.png`} alt="Inventory Logo" className="header-logo" />
              <div className="card-content">
                <h2>Inventering - Launching later in early 2025</h2>
              </div>
            </div>
          </a>


        </div>
      </main>

      <footer className="app-footer">
        <div className="footer-content">
          <span className="copyright-text">© 2025 RetailX. All rights reserved.</span>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
